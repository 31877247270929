import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pm from '../../assets/media/pm.jpeg'
import axios from 'axios';
import { FaLocationDot } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import VimeoPlayer from "../../component/VimeoVideo";
import Req from '../../assets/svg/requirement.svg';
import Free from '../../assets/svg/free.svg';
import QLT from '../../assets/svg/quality.svg';
import Leave from '../../assets/svg/leave.svg';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import Best from '../../assets/svg/best.svg';
import Lowest from '../../assets/svg/lowest.svg';
import Move from '../../assets/svg/move.svg';
import Reschedule from '../../assets/svg/reschedule.svg';
import Button from 'react-bootstrap/Button';
import OTPInput from "otp-input-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';


const PackersMovers = () => {
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [hide,setHide] = useState(false);
    const [address, setAddress] = useState('');
    const [addressMove, setAddressMove] = useState('');

    const handleSelect = async (selectedAddress) => {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setAddress(selectedAddress);
    };

    const handleSelecttWO = async (selectedAddress) => {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setAddressMove(selectedAddress);
    };
  
  
  const [formData, setFormData] = useState({
    "currentAddress": '',
    "whereToMove": "",
    "tempoOrNot": "yes",
    "homeSize": 0,
    "movingDate": "",
    "phoneNumber":""
  });
  

  const vimeoVideoId = '901150053';

  const currentDate = new Date().toISOString().split('T')[0];
  const userToken = localStorage.getItem("userToken");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleBookingSubmit =async () => {
    const data = {
      "currentAddress": address,
    "whereToMove": addressMove,
    "tempoOrNot": "yes",
    "homeSize": formData.homeSize,
    "movingDate": formData.movingDate,
    "phoneNumber":formData.phoneNumber
    }
    axios.post('https://ehb.easyhaibro.com/auth/v1/packers', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      }
    })
      .then(async (response) => {
        if (response.status === 201) {
            await Swal.fire({
                title: "Booking Successful",
                text: "We will connect with you very soon!",
                icon: "success",
              });
            
              window.location.reload();

          }

      })
      .catch((error) => {
        console.error(error);
      });
  };


  const handleGoBack =()=>{
    navigate.goBack()
  }

  const handleLogin = ()=>{
    navigate('/login');
  }

  const handleSendOtp =()=>{
    const qs = require('qs');
  let data = qs.stringify({
    'To': `+91${phoneNumber}`,
    'Channel': 'sms' 
  });
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://verify.twilio.com/v2/Services/VAf74968ce30586ad48fb747acd2a78891/Verifications',
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded', 
      'Authorization': 'Basic QUNlYWVhMWU4ZWRlYmEwN2QyMjk3ZDhjODVjZmY0YjM0NzowYzQ0MGNmOGU3MTNhNGI0ODZhYWQ3M2I3MjA3YjRjMA=='
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    setHide(true);
  })
  .catch((error) => {
    console.log(error);
  });
  
  }

  const veirfyOtp =()=>{
    const qs = require('qs');
    let data = qs.stringify({
      'To': `+91${phoneNumber}`,
      'Code':otp 
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://verify.twilio.com/v2/Services/VAf74968ce30586ad48fb747acd2a78891/VerificationCheck',
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded', 
        'Authorization': 'Basic QUNlYWVhMWU4ZWRlYmEwN2QyMjk3ZDhjODVjZmY0YjM0NzowYzQ0MGNmOGU3MTNhNGI0ODZhYWQ3M2I3MjA3YjRjMA=='
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
    
      if(response.data.status === 'approved'){
        let data = JSON.stringify({
          "phone": phoneNumber
        });
        
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_HOST}verify-otp`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config)
        .then((response) => {
          localStorage.setItem("userToken", response.data.userToken);
    handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
        
        
      }
    })
    .catch((error) => {
      console.log(error);
    });
    
  }



  return (
    <>
      <Container fluid className="container category">
      <div className="hyperlinks">
          <a href="/" >Home</a> {` `}/
          <a onClick={handleGoBack} id="activeLink">{`Packers & Movers`}</a>
          <h2>{`Packers & Movers`}</h2>
          <p id="subhead">Best Packers and Movers in Mumbai</p>
        </div>

        <Container fluid className="container lg-ds">

          <Row>
            <Col lg={6} md={12} sm={12}>
            <VimeoPlayer videoId={vimeoVideoId} />
            </Col>
            <Col lg={6} md={12} sm={12}>
              <div className="booking-details">
                <h2>Where are you relocating?</h2>
                <div className="booking-label">
                  <label>Select Your Current Address</label>
                  <FaLocationDot />
                  <PlacesAutocomplete
        value={address}
        onChange={(value) => setAddress(value)}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Type Your Current Address...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
                </div>
                <div className="booking-label">
                  <label id="locality">Where you want to move?</label>
                  <FaLocationDot />
                  <PlacesAutocomplete
        value={addressMove}
        onChange={(value) => setAddressMove(value)}
        onSelect={handleSelecttWO}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Type your address where you want to move...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
                </div>
                <div className="booking-label">
                  <label id="locality">What is the size of your home</label>
                  <select name="homeSize" onChange={handleChange}>
                  <option value="">Select</option>
                    <option value="1">1BHK</option>
                    <option value="2">2BHK</option>
                    <option value="3">3BHK</option>
                    <option value="4">4BHK</option>
                    <option value="0">Other</option>
                  </select>
                </div>
                <div className="booking-label">
                  <label id="locality">When you want to move?</label>
                  <input type="date" min={currentDate} name="movingDate" onChange={handleChange} />
                </div>
                {/* <div className="booking-label">
  <label id="locality">Need city tempo?</label>
  <select name="tempoOrNot" onChange={handleChange}>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</div> */}

                <div className="booking-label">
                  {userToken && 
                   <input type="submit" id="submit" value="Get Estimate" onClick={handleBookingSubmit} />
}
{!userToken && 
<input
  type="submit"
  id={(
    address === "" || addressMove=== "" || 
    formData.homeSize === 0 ||
    formData.movingDate === "") ? "submit-disabled" : "submit"}
  value="Check Price"
  onClick={handleShow}
  disabled={

    address === "" || addressMove=== "" || 
    formData.homeSize === 0 ||
    formData.movingDate === ""
  }
/>
}


                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="container lg-ds">
        <div className="services how">
          <h2>How it works?</h2>
            <div className="services-div">
              <img src={Req}/>
              <p>Share your Shifting Requirement</p>
              <span>Help us by providing when and where do you want to move</span>
            </div>
            <div className="services-div">
              <img src={Free}/>
              <p>Receive Free Instant Quote</p>
              <span>Get guaranteed lowest priced quote for your shifting instantly</span>
            </div>
            <div className="services-div">
              <img src={QLT}/>
              <p>Assign Quality Service Expert</p>
              <span>To ensure safe relocation quality service expert will be allotted or your movement</span>
            </div>
            <div className="services-div">
              <img src={Leave}/>
              <p>Leave the Heavy Lifting to Us</p>
              <span>Enjoy hassle-free on time movement of your household goods</span>
            </div>
          </div>
        </Container>

        <Container fluid className="container lg-ds">
        <div className="services how why">
          <h2>Why Easy Hai Bro | Best Packers & Movers in Mumbai.</h2>
            <div className="services-div">
              <img src={Lowest}/>
              <p>Lowest Price Guarantee</p>
              <span>Moving at a price you can afford - we’ll match any competitor’s quote</span>
            </div>
            <div className="services-div">
              <img src={Best}/>
              <p>Best Quality Service</p>
              <span>Safe and Reliable Packaging and Moving Services</span>
            </div>
            <div className="services-div">
              <img src={Reschedule}/>
              <p>Free Reschedule and Cancellation</p>
              <span>Reschedule anytime and fully refundable token on the cancellation.</span>
            </div>
            <div className="services-div">
              <img src={Move}/>
              <p>Dedicated Move Manager</p>
              <span>Exceeding expectations, one customer at a time</span>
            </div>
          </div>
        </Container>


        <Container className="faqs">
        <h2 id="faqsHead">Frequently Asked Questions.</h2>
        <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>What happens to my booking if I have to cancel or change the date?</Accordion.Header>
        <Accordion.Body>
        We understand that plans can change at any time. This is why you get a Free Cancellation policy when you book with Easy Hai Bro Packers and movers. There is no risk and you don't lose out on anything when you cancel your booking.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Is it possible to find a better price for packers and movers?</Accordion.Header>
        <Accordion.Body>
        Easy Hai Bro Packers and Movers are the most affordable option you will find! The services start as low as Rs.4950 and depends on how far and how much you need to move. You also get a Best Price Guarantee! This means that you will not find a lower price in the market. If you do, we will match it (or beat it) and also give you a service guarantee! Here is how much it will cost you to move with Easy Hai Bro Packers & Movers –
BHK TYPEBase price
1 RK4950
1 BHK5800
2 BHK7560
3 BHK13500
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>Why should I pay token in advance before the move?</Accordion.Header>
        <Accordion.Body>
        The token is to confirm the slot bookings and avoid any last minute delays or inconvenience. The token amount will be adjusted in the final payment of your quotation.
        </Accordion.Body>
      </Accordion.Item>



      <Accordion.Item eventKey="4">
        <Accordion.Header>Can I reschedule my movement after I have paid the token amount?</Accordion.Header>
        <Accordion.Body>
        You can reschedule your movement by informing your dedicated move manager whose details are included in the confirmation email. Your move manager will reschedule the movement based on the availability of slots
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="5">
        <Accordion.Header>Will the movers and packers Mumbai also dismantle beds and other furniture?</Accordion.Header>
        <Accordion.Body>
        Yes, Easy Hai Bro packers and movers in Mumbai can dismantle and assemble beds and other furniture without any extra cost. However, you will have to notify your dedicated move manager before movement.
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="6">
        <Accordion.Header>We have very few items to move. The quotation is very costly.</Accordion.Header>
        <Accordion.Body>
        Please contact us at ++919004044078. We can arrange a small transportation for such movements at a lower cost.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
        </Container>


      </Container>


      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Enter your details to continue!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="booking-label modal-phone">
        {hide === false && 
        <>
          <label>Phone Number</label>
          <input
          minLength={10}
  type='number'
  placeholder="Enter phone number"
  value={phoneNumber}
  onChange={(e) => setPhoneNumber(e.target.value)}
/>
<Button id="submit-phone" onClick={handleSendOtp}>
            Send OTP
          </Button>
</>
}
          {hide === true && 
       <>
          <div className="booking-label modal-phone">
            <label>Enter OTP</label>
            <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={5} otpType="number" />
            </div>
       


          <Button id="submit-phone" onClick={veirfyOtp}>
            Verify OTP
          </Button>
          </>
}
        </div>
      </Modal.Body>

      <Modal.Footer>
     
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default PackersMovers;
