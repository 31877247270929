import React from "react";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { HiOutlineCash } from "react-icons/hi";
import { GrUserExpert } from "react-icons/gr";
import { GiTransparentSlime } from "react-icons/gi";

const Hyperlinks = () => {
    const { categoryName } = useParams();

    const categoryNameParam = categoryName
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return (
        <main className="hyperlinks">
            <nav aria-label="Breadcrumb">
                <Link to="/">Home</Link> / <span id="activeLink">{categoryNameParam}</span>
            </nav>
            <header>
                <h1>{categoryNameParam} At Home</h1>
                <p>Service that fits your budget with quality assurance!</p>
            </header>
            <section className="ehb-protection">
                <article className="ehb-prot-div one">
                    <VscWorkspaceTrusted aria-label="100% Satisfaction Guaranteed" />
                    <div className="ehb-menu">
                        <h2>100% Satisfaction Guaranteed</h2>
                        <p>If you're not satisfied, we offer a full refund to ensure your peace of mind.</p>
                    </div>
                </article>
                <article className="ehb-prot-div two">
                    <HiOutlineCash aria-label="Cash Payment Available" />
                    <div className="ehb-menu">
                        <h2>Cash Payment Available</h2>
                        <p>Conveniently pay in cash for our services, providing you with flexibility in payment options.</p>
                    </div>
                </article>
                <article className="ehb-prot-div three">
                    <GrUserExpert aria-label="Expert Care Professionals" />
                    <div className="ehb-menu">
                        <h2>Expert Care Professionals</h2>
                        <p>Our passionate team of experienced professionals ensures the highest standard of care for your pets.</p>
                    </div>
                </article>
                <article className="ehb-prot-div four">
                    <GiTransparentSlime aria-label="Transparent and Flexible" />
                    <div className="ehb-menu">
                        <h2>Transparent and Flexible</h2>
                        <p>Open communication and fair, straightforward policies for a positive experience.</p>
                    </div>
                </article>
            </section>
        </main>
    );
};

export default Hyperlinks;
