import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import axios from "axios";


function DetailsModal({ show, handleClose, petname, gender,serviceId }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    address: '',
    phoneNumber: '',

  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      serviceId: serviceId
    }));
  };

  const handleSubmit = () => {
    console.log('Form Data:', formData);
let data = formData;

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://ehb.easyhaibro.com/auth/v3/pet-enquiry',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': `Bearer ${localStorage.getItem('userToken')}`
  },
  data : data
};

axios.request(config)
.then((response) => {
  if(response.data.status === true){
    window.location.href="/thank-you";
  }
})
.catch((error) => {
  console.log(error);
});

  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Enter your details to continue!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        <div className='pet-type-details'>
          <p>You wish to check the price for:</p>
          <p>Pet Name: <strong>{petname}</strong></p>
          <p>Gender: <strong>{gender}</strong></p>
        </div>

        <div className="booking-label modal-phone">
          <label>Full Name:</label>
          <input
            name="fullName"
            minLength={1}
            type='text'
            placeholder="Enter your Full Name"
            value={formData.fullName}
            onChange={handleChange}
          />
         
        </div>

        <div className="booking-label modal-phone">
          <label>Email:</label>
          <input
            name="email"
            minLength={1}
            type='email'
            placeholder="Enter your email id"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className="booking-label modal-phone">
          <label>Address:</label>
          <input
            name="address"
            minLength={1}
            type='text'
            placeholder="Enter your Address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>

        <div className="booking-label modal-phone">
          <label>Phone Number:</label>
          <input
            name="phoneNumber"
            minLength={10}
            type='number'
            placeholder="Enter phone number"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </div>

        <Button id="submit-phone" onClick={handleSubmit}>
          Submit details
        </Button>

      </Modal.Body>

      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
}

export default DetailsModal;
