import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DOMPurify from 'dompurify';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import CT from '../../assets/media/do.png';
import { useParams } from "react-router-dom";
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import moment from "moment-timezone";
import InputMask from 'react-input-mask';

import { css } from '@emotion/react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Forum from "../../component/Forum";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const MySwal = withReactContent(Swal);
const ViewDetails = () => {
  const [loading,setLoading]= useState(false);
  const [serviceData, setServiceData] = useState({});
  const userToken = localStorage.getItem("userToken");

  const [expanded, setExpanded] = useState(false);

  const navigate = useNavigate();
  const url = window.location.href;
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  const sanitizedHtml = DOMPurify.sanitize(serviceData.serviceDescription);


  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await axios.get(`https://ehb.easyhaibro.com/auth/v1/service/${lastPart}`, {
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
        });

        setServiceData(response.data);
      } catch (error) {
        console.error("Error fetching service details:", error);
      }
    };

    fetchServiceDetails();
  }, [userToken]);


  const handleGoBack =()=>{
    navigate.goBack()
  }

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

const handleBook =(serviceId)=>{
    window.location.href = `/book-service/${serviceId}`;
}


  return (
    <>
      <Container fluid className="container category view-details">
        <div className="hyperlinks">
          <a href="/" >Home </a> {` `}/
          <a onClick={handleGoBack} id="activeLink">{serviceData.categoryName}</a>
          <h2>{serviceData.serviceName}</h2>
        </div>

        <Container fluid className="container lg-ds">
          <Row className="orderd">
          <Col lg={6} md={12} sm={12}>
      <div  >
        <div className="category-img">
          <img src={serviceData.serviceImage} alt="Service Thumbnail" />
        </div>
        <div className="category-details">
          <h3 id="price">₹ {serviceData.servicePrice}</h3>
          <p>
            <span id="review">{serviceData.reviews} Reviews</span>{' '}
            <span id="number">{`9.3`}</span>
          </p>
       
          <p id="descFull" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        </div>
        
      </div>
      <button onClick={handleToggleExpand} id="viewMore">
        {expanded ? 'View Less' : 'View More'}
      </button>
      <button onClick={()=>handleBook(serviceData.allDetails._id)} id="wid100">
       Book Now
      </button>
    
    </Col>

          </Row>
        </Container>
      </Container>
     
     
     {loading &&
      <div class="kinetic"></div>
     }
    </>
  );
};

export default ViewDetails;