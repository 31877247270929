import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
const HowGrooming = ({ label, children }) => {
  return (

<Container fluid className="container lg-ds category-page">
<h2 id="howto">How our "at-home" pet grooming works</h2>
<Row>
 <Col lg={6} md={12} sm={12}>

   {/* repeated item */}
   <div className="howto-div">
     <img src="https://ehb.easyhaibro.com/auth/v1/media/?file=1706811162231.svg" />
     <div className="howto-menu">
        <h3>Book Your Session</h3>
        <p> Schedule a convenient grooming appointment on our website or through a quick call.</p>
     </div>
   </div>
 {/* ended here */}

 {/* repeated item */}
 <div className="howto-div">
     <img src="https://ehb.easyhaibro.com/auth/v1/media/?file=1706811631531.svg" />
     <div className="howto-menu">
        <h3>Groomers Arrive at Your Doorstep:</h3>
        <p>Our certified grooming experts come to your home fully equipped for the session.</p>
     </div>
   </div>
 {/* ended here */}


 {/* repeated item */}
 <div className="howto-div">
     <img src="https://ehb.easyhaibro.com/auth/v1/media/?file=1706811812770.svg" />
     <div className="howto-menu">
        <h3>Tailored Grooming for Your Pet</h3>
        <p>Enjoy personalized grooming services designed to meet your pet's unique needs.</p>
     </div>
   </div>
 {/* ended here */}


 {/* repeated item */}
 <div className="howto-div">
     <img src="https://ehb.easyhaibro.com/auth/v1/media/?file=1706811835578.svg" />
     <div className="howto-menu">
        <h3>No Mess, Just Bliss:</h3>
        <p>After the pampering, our groomers leave your space spotless, and you make an easy, stress-free payment.</p>
     </div>
   </div>
 {/* ended here */}

 </Col>
 <Col lg={6} md={12} sm={12}>
   <img id="howto-right" src="https://ehb.easyhaibro.com/auth/v1/media/?file=1706812087715.jpg" />
 </Col>
</Row>
</Container>
  )};
  export default HowGrooming;