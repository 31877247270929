import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner'; // Import Spinner component
import { useCategories, handleService } from '../services/services';
import { useNavigate } from "react-router-dom";
import { ShimmerPostItem } from "react-shimmer-effects";

const ServiceComponents = () => {
  const categories = useCategories();
  const navigate = useNavigate();

  const handleClick = (val) => {
    handleService(val, navigate);
  };

  // Display loading spinner if categories are still loading
  if (!categories) {
    return (
      <Container fluid className="container lg-ds">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="services">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  
  if (categories.length === 0) {
    return (
      <Container fluid className="container lg-ds">
         <div className="services">
            <h2>Popular Categories</h2>
            <p>Browse by category</p>
          </div>
          <Row>
          <div className='ehb-row shimmer-main'>
          
                 <ShimmerPostItem card title cta />
                 <ShimmerPostItem card title cta />
                 <ShimmerPostItem card title cta />
                 <ShimmerPostItem card title cta />
                 <ShimmerPostItem card title cta />
                 <ShimmerPostItem card title cta />
               </div>
         
        </Row>
      </Container>
    );
  }


  return (
    <Container fluid className="container lg-ds">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="services">
          <h2>Popular Categories</h2>
            <p>Browse by category</p>
          </div>
          <Row>
            {categories.map(category => (
              <div className="services-list" key={category._id} onClick={() => handleClick(category.pageRoute)}>
               
                <img src={category.imageUrlPng} alt={category.categoryName} />
                <h3>{category.categoryName}</h3>
              </div>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ServiceComponents;
