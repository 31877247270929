import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectPage() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/my-bookings');
    }, []);

    return (
        <div>
            <h1>Redirect Page</h1>
            {/* You can add more content here if needed */}
        </div>
    );
}

export default RedirectPage;
