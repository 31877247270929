import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import DOMPurify from 'dompurify';
import moment from "moment-timezone";
import { Link, useNavigate } from 'react-router-dom';
import { ShimmerPostList } from "react-shimmer-effects";

const MyBookingsComp = () => {
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true); // State to track loading state
    const userToken = localStorage.getItem("userToken");
    const navigate = useNavigate();

    const fetchBookings = async () => {
        try {
          const response = await axios.get('https://ehb.easyhaibro.com/auth/v1/my-bookings', {
            headers: {
              'Authorization': `Bearer ${userToken}` // Replace with your actual access token
            }
          });
          setBookings(response.data.data);
          setLoading(false); // Data loaded, set loading state to false
        } catch (error) {
          console.error(error);
          setLoading(false); // Error occurred, set loading state to false
        }
      };

    useEffect(() => {
      fetchBookings();
    }, [userToken]);

    const handleService = (value) => {
        console.log(value);
        navigate(`/book-service/${value}?-----my-bookings`);
        window.scrollTo(0, 0);
    }

    const handleCall = () => {
        
        window.location.href = 'tel:+919468330023'; 
    };
    

    return (
        <>
            <div className="category-services">
                {loading ? ( // If data is loading, show shimmer effect
                    <ShimmerPostList />
                ) : (
                    bookings.length === 0 ? (
                        <div>No bookings found</div>
                    ) : (
                        bookings.map(booking => (
                            <div className="category-service-repeat mybookings">
                                <div className="category-img">
                                    <img src={booking.service.serviceImage} alt="Service" />
                                </div>
                                <div className="category-details ">
                                    <h2>{booking.service.serviceName}</h2>
                                    <h3 id="price">₹ {booking.service.servicePrice}</h3>
                                    <p><span id="review">Service Status</span><br /><span id={booking.bookingStatus}>
                                        {booking.bookingStatus === "pending" && (
                                            <>Service Provider will be allocated soon.</>
                                        )}
                                        {booking.bookingStatus === "inprogress" && (
                                            <>Service Provider Allocated</>
                                        )}
                                        {booking.bookingStatus === "delivered" && (
                                            <>Service Completed</>
                                        )}
                                    </span></p>
                                    <p><span id="review">Service Date</span> <span id="date">{moment(booking.pickUpDate).format("DD/MM/YYYY")}</span></p>
                                    <p><span id="review">Service Slot</span> <span id="date">{booking.serviceSlot}</span></p>
                                    {booking.bookingStatus != 'cancelled' &&
                                    <> 
                                    {booking.paymentStatus.paymentStatus === 'COMPLETED' &&
                                    <p><span id="review">Payment Status</span> <span id="completed">{booking.paymentStatus.paymentStatus}</span></p>
}
{booking.paymentStatus.paymentStatus != 'COMPLETED' &&
                                    <p><span id="review">Payment Status</span> <span id="inprogress">PENDING</span></p>
}


</>
}
{booking.bookingStatus == 'cancelled' &&
                                    <> 
                                    {booking.paymentStatus.paymentStatus === 'COMPLETED' &&
                                    <p><span id="review">Payment Status</span> <span id="red">{booking.bookingStatus}</span></p>
}
{booking.paymentStatus.paymentStatus != 'COMPLETED' &&
                                    <p><span id="review">Payment Status</span> <span id="inprogress">PENDING</span></p>
}
</>
}

                                    <button onClick={() => handleCall()}>Call Support</button>
                                    
                                </div>
                            </div>
                        ))
                    )
                )}
            </div>
        </>
    );
};

export default MyBookingsComp;
