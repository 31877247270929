import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import one from '../assets/media/1.png'
import two from '../assets/media/2.png'
import three from '../assets/media/3.png'
const HowItWorksComponent = () => {
return(
    <Container fluid className="container lg-ds how">
    <Row>
      <Col lg={12} md={12} sm={12}>
         <div className="services">
           <h2>How it's works?</h2>
         
         </div>

         <div className="services-dv nd">
           <div className="work-list">
           <img src={one} id="service"/>
           <p>Explore your service</p>
         
           
           </div>
           <div className="work-list">
           <img src={two} id="service"/>
           <p>Book your service</p>
         
           
           </div>
           <div className="work-list">
           <img src={three} id="service"/>
           <p>Relax!</p>
         
           
           </div>
          
         </div>
         
      </Col>

      
    
    </Row>
  </Container>


);
};

export default HowItWorksComponent;