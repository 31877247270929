import React, { useState } from 'react';
import { CiSquarePlus } from "react-icons/ci";
import { FaClipboardList } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from 'axios';

function KennalInformation() {
  const [loading, setLoading] = useState(false);
  const userToken = localStorage.getItem('userToken');
  const [formData, setFormData] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSaveAndList = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_HOST}kennal`, formData, {
        headers: {
          'Authorization': `Bearer ${userToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 201) {
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Kennal Information Submitted successfully.'
        }).then(() => {
          window.location.reload();
        });
      } else {
        throw new Error('Failed to submit Kennal information.');
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to submit Kennal information. Please try again.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container topContainer">
      <div className="ehb-row">
        <div className="ehb3">
          <div className="section-left">
            <a href="/kennal-information">
              <div className="active-section">
                <CiSquarePlus id="plus" /> Kennal Information
              </div>
            </a>
            <a href="/adoption">
              <div className="non active-section">
                <CiSquarePlus id="plus" /> Add Your Pet
              </div>
            </a>
            <a href="/my-pets">
              <div className="non active-section">
                <FaClipboardList id="plus" /> My Pet Lists
              </div>
            </a>
          </div>
        </div>
        <div className="ehb7">
          <div className="section-left">
            <div className="ehb-column">
              <div className="ehb10 ehb-main">
                <h2>Add Your Breeding Information</h2>
                <p>Please fill this information. It will help us to know about your pet.</p>
              </div>
              <div className="ehb-row">
                <div className="ehb5">
                  <div className="label-input">
                    <label>Are You a Professional Breeder?*</label>
                    <select name="professionalBreeder" value={formData.professionalBreeder} onChange={handleInputChange}>
                      <option>Select Type</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
                {formData.professionalBreeder === 'yes' &&
                  <>
                    <div className="ehb5">
                      <div className="label-input">
                        <label>Kennal Name*</label>
                        <input type="text" name="kennalName" value={formData.kennalName} onChange={handleInputChange} />
                      </div>
                    </div>
                    <div className="ehb5">
                      <div className="label-input">
                        <label>Website (If Any)</label>
                        <input type="text" name="kennalWebsite" value={formData.kennalWebsite} onChange={handleInputChange} />
                      </div>
                    </div>
                  </>
                }
                <div className="ehb5">
                  <div className="label-input">
                    <label>Do You Take Your Dog to Dog Shows?*</label>
                    <select name="dogShow" value={formData.dogShow} onChange={handleInputChange}>
                      <option>Select Type</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
                <div className="ehb5">
                  <div className="label-input">
                    <label>When Did You Start Breeding? (Year)*</label>
                    <input type="number" placeholder='2023' name="startBreeding" value={formData.startBreeding} onChange={handleInputChange} />
                  </div>
                </div>
                <div className="ehb5">
                  <div className="label-input">
                    <label>Do You Have a Vet For Your Pet?*</label>
                    <select name="vetPet" value={formData.vetPet} onChange={handleInputChange}>
                      <option>Select Option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
                {formData.vetPet === 'yes' &&
                  <>
                    <div className="ehb5">
                      <div className="label-input">
                        <label>Vet Name</label>
                        <input type="text" name="vetName" value={formData.vetName} onChange={handleInputChange} />
                      </div>
                    </div>
                    <div className="ehb5">
                      <div className="label-input">
                        <label>Vet Contact</label>
                        <input type="text" name="vetContact" value={formData.vetContact} onChange={handleInputChange} />
                      </div>
                    </div>
                  </>
                }
                <div className="ehb5">
                  <div className="label-input">
                    <label>Cancellation Policy*</label>
                    <select name="cancellationPolicy" value={formData.cancellationPolicy} onChange={handleInputChange}>
                      <option>Select Option</option>
                      <option value="low">Low</option>
                      <option value="medium">Medium</option>
                      <option value="high">High</option>
                    </select>
                  </div>
                </div>
                <div className="ehb5">
                  <div className="label-input">
                    <label>I Breed*</label>
                    <select name="breedType" value={formData.breedType} onChange={handleInputChange}>
                      <option>Select Option</option>
                      <option value="dog">Dog</option>
                      <option value="cat">Cat</option>
                    </select>
                  </div>
                </div>
                <div className="ehb10">
                  <div className="label-input">
                    <label>Describe Your Breeding Practices (Why You Love Breeding? Why You Are a Good Breeder?)*</label>
                    <textarea name="breedingPractices" value={formData.breedingPractices} onChange={handleInputChange} />
                  </div>
                </div>
                <div className="ehb10">
                  <div className="label-input">
                    <label>I Breed In (Breeds)*</label>
                    <textarea name="breedDescription" value={formData.breedDescription} onChange={handleInputChange} />
                  </div>
                </div>
                <div className="ehb-row">
                  <div className="ehb10 right-btn">
                    <button onClick={handleSaveAndList}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <div className="kinetic"></div>}
    </div>
  );
}

export default KennalInformation;
