import React, { useState } from "react";
import axios from "axios";
import Container from 'react-bootstrap/Container';
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const Signup = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");

  const handleLogin = () => {
    const data = {
      fullName:fullName,
      email: email,
      password: password,
      phone:phone
    };

    axios.post('https://ehb.easyhaibro.com/auth/v1/registration', data)
      .then((response) => {
        if (response.data.status) {
          localStorage.setItem("userToken", response.data.userToken);
          navigate("/my-bookings");
        
          window.location.reload();
        }
        
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Container fluid className="container">
      <div className="login-sc">
        <div className="logn-form">
          <h1>Create New Account!</h1>
          <label>Full Name</label>
          <input
            type="text"
            placeholder="Harry"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <label>Email Address</label>
          <input
            type="email"
            placeholder="xyz@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>Phone Number</label>
          <input
            type="number"
            placeholder="+91 0000000000"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <label>Password</label>
          <input
            type="password"
            placeholder="*******"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={handleLogin}>Singup</button>
          <Link to="/login">Login</Link>
        </div>
      </div>
    </Container>
  );
};

export default Signup;
