import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import CT from '../../assets/media/do.png';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from '../../assets/media/car.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Not from '../../assets/media/4040.svg';
const NotFound = () => {
  
  return (
    <>

  <Container fluid className="container category">
     <div className="hyperlinks">
      <a href="/" >Home</a> {` `}/
      <a href="" id="activeLink">404</a>
      <h2>Oh Sorry!, Page Not Found</h2>
      <img src={Not} id="404"/>
     </div>

    
   
   


  
    </Container>

     
    </>
  );
}

export default NotFound;
