import React, { useState,useEffect } from 'react';
import { CiSquarePlus } from "react-icons/ci";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { IoIosInformationCircle } from "react-icons/io";
import { CiBookmarkCheck } from "react-icons/ci";
import { FaClipboardList } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from 'axios';
import { useParams } from 'react-router-dom';
function EditAdoption() {
    const { serviceId } = useParams();
      const [imageformData, setFormDataImage] = useState({
    serviceImage: null,  
  });
  const [formDataVideo, setFormDatVideo] = useState({
    petImages: [null],  
  });
  const [loading, setLoading] = useState(false);
  const defaultImg = "https://image-assets.eu-2.volcanic.cloud/api/v1/assets/images/391ffeaf6a4f87f52f34d9d5874ee869?t=1705059868&webp_fallback=png";
  const deafaultVideo = "https://ehb.easyhaibro.com/auth/v1/media/?file=1714821415207.png";
  const userToken = localStorage.getItem('userToken');
  const [formData, setFormData] = useState({
    petImages: [],
    serviceName: "",
    dateOfBirth: "",
    petType: "",
    petBreed: "",
    petGender: "male",
    Vaccinated: "",
    state: "",
    city: "",
    serviceDescription: "",
    serviceType: "adoption",
    serviceCity:''
  });

  useEffect(() => {
    fetchServiceData();
  }, []);
  
  const fetchServiceData = async () => {

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}service/${serviceId}`,
      headers: { 
        'Authorization': `Bearer ${userToken}`
      }
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      const serviceData = response.data.allDetails;
      setFormData({
        ...formData,
        serviceName: serviceData.serviceName,
        dateOfBirth: serviceData.dateOfBirth,
        petType: serviceData.petType,
        petBreed: serviceData.petBreed,
        petGender: serviceData.petGender,
        Vaccinated: serviceData.Vaccinated,
        state: serviceData.serviceState,
        city: serviceData.serviceCity,
        serviceDescription: serviceData.serviceserviceDescription,
        serviceCity: serviceData.serviceCity,
        servicePrice:serviceData.servicePrice,
        serviceImage:serviceData.serviceImage
      
      });
    })
    .catch((error) => {
      console.log(error);
    });
    
  };



  const Link = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a href="#">{children}</a>
    </OverlayTrigger>
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleSaveAndList = async () => {
    setLoading(true);
    try {
      const response = await axios.patch(`${process.env.REACT_APP_HOST}service/${serviceId}`, formData, {
        headers: {
          'Authorization': `Bearer ${userToken}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (response.status === 201) {
        setLoading(false);
        Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Pet Adoption Edited successfully.'
          }).then(() => {
            window.location.reload();
          });
      } else {
        throw new Error('Failed to Update Details.');
      }
    } catch (error) {
        setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to upload banner. Please try again.'
      });
    } finally {
      setLoading(false);
    }
  };
  const handleChangeImage = (e) => {
    
    setFormDataImage({
      ...imageformData,
      serviceImage: e.target.files[0],
    });
  };


  const handleUploadImage = () => {
    setLoading(true);
    const data = new FormData();
    data.append('mediaKey', imageformData.serviceImage);

    
    const config = {
      headers: {
        'Authorization': `Bearer ${userToken}`, 
      },
    };

    
    axios.post(`https://ehb.easyhaibro.com/auth/v1/media-upload`, data, config)
      .then((response) => {      
        setFormData({
          ...formData,
          ['serviceImage']: response.data.mediaKey,
        });
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Banner Uploaded successfully.',
        });

      })
      .catch((error) => {
        console.log(error);
       
      });
  };


  return (
    <div className="container topContainer">
      <div className="ehb-row">
        <div className="ehb3">
          <div className="section-left">
            <a href="/adoption">
              <div className="non active-section">
                <CiSquarePlus id="plus" /> Add Your Pet
              </div>
            </a>
            <a href="/my-pets">
              <div className="non active-section">
                <FaClipboardList id="plus" /> My Pet Lists
              </div>
            </a>
            <a href="/my-pets">
              <div className="active-section">
                <FaClipboardList id="plus" /> Edit {formData.serviceName}
              </div>
            </a>
          
          </div>
        
        </div>
        <div className="ehb7">
            <div className="section-left">
              <div className="ehb-column">
                <div className="ehb10 ehb-main">
                  <h2>View/Edit Your Pet</h2>
                  <p>Please fill this information. It will help us to know about your pet.</p>
                </div>

                <div className="ehb-row">
                  <div className="ehb10">
                    <div className="label-input">
                      <label>
                        Share Latest Photos <Link id="t-2" title="max 2 mb, Image should be latest & clear">
                          <IoIosInformationCircle />
                        </Link>
                      </label>
                      {imageformData && 
                     <> <img src={formData.serviceImage || defaultImg} id="bannerService" /></>
                      }
                     <input type="file" name="mediaKey" accept=".png, .svg, .jpg, jpeg" onChange={handleChangeImage} />
        <button onClick={handleUploadImage}>Upload Image</button>
                    </div>

                   
                  </div>
                </div>

               

                <div className="ehb-row">
                  <div className="ehb5">
                    <div className="label-input">
                      <label>Pet Name/Title*</label>
                      <input type="text" placeholder='Eg: Husky' name="serviceName" value={formData.serviceName} onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className="ehb5">
                    <div className="label-input">
                      <label>Date of Birth*</label>
                      <input type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleInputChange} />
                    </div>
                  </div>

                  <div className="ehb5">
                    <div className="label-input">
                      <label>Pet Type*</label>
                      <select name="petType" value={formData.petType} onChange={handleInputChange}>
                        <option>Select Type</option>
                        <option value="dog">Dog</option>
                        <option value="cat">Cat</option>
                        
                      </select>
                    </div>
                  </div>

                  <div className="ehb5">
                    <div className="label-input">
                      <label>Pet Breed*</label>
                      <select name="petBreed" value={formData.petBreed} onChange={handleInputChange}>
                      {(formData.petType != "dog" && formData.petType != "cat") &&
                      <option>Select Pet Type First</option>
                  }
                        {formData.petType === "dog" &&
                        <>
                        <option>Select Dog Breed</option>
                      
  <option value="labrador_retriever">Labrador Retriever</option>
  <option value="german shepherd">German Shepherd</option>
  <option value="golden retriever">Golden Retriever</option>
  <option value="bulldog">Bulldog</option>
  <option value="poodle">Poodle</option>
  <option value="beagle">Beagle</option>
  <option value="rottweiler">Rottweiler</option>
  <option value="yorkshire terrier">Yorkshire Terrier</option>
  <option value="boxer">Boxer</option>
  <option value="dachshund">Dachshund</option>
  <option value="shih tzu">Shih Tzu</option>
  <option value="siberian husky">Siberian Husky</option>
  <option value="doberman pinscher">Doberman Pinscher</option>
  <option value="great dane">Great Dane</option>
  <option value="border collie">Border Collie</option>
  </>
  }
      {formData.petType === "cat" &&
                        <>
    <option value="">Select Cat Breed</option>
  <option value="persian">Persian</option>
  <option value="maine coon">Maine Coon</option>
  <option value="siamese">Siamese</option>
  <option value="ragdoll">Ragdoll</option>
  <option value="british shorthair">British Shorthair</option>
  <option value="abyssinian">Abyssinian</option>
  <option value="sphynx">Sphynx</option>
  <option value="bengal">Bengal</option>
  <option value="scottish fold">Scottish Fold</option>
  <option value="burmese">Burmese</option>
  <option value="russian blue">Russian Blue</option>
  <option value="norwegian forest">Norwegian Forest</option>
  <option value="egyptian mau">Egyptian Mau</option>
  <option value="devon rex">Devon Rex</option>
  </>
  }
                      </select>
                    </div>
                  </div>

                  <div className="ehb5">
                    <div className="label-input">
                      <label>Gender*</label>
                      <select name="petGender" value={formData.petGender} onChange={handleInputChange}>
                        <option>Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>

                  <div className="ehb5">
                    <div className="label-input">
                      <label>Vaccinated?*</label>
                      <select name="Vaccinated" value={formData.Vaccinated} onChange={handleInputChange}>
                        <option>Select Option</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="ehb5">
                    <div className="label-input">
                      <label>Pet Price*</label>
                      <input type="number" placeholder='0.00' name="servicePrice" value={formData.servicePrice} onChange={handleInputChange} />
                      
                    </div>
                  </div>


                  <div className="ehb5">
                    <div className="label-input">
                      <label>Select City?*</label>
                      <select name="serviceCity" value={formData.serviceCity} onChange={handleInputChange}>
                        <option>Select City</option>
                        <option>Mumbai</option>
                        <option>Bengaluru</option>
                      </select>
                    </div>
                  </div>

                  <div className="ehb10">
                    <div className="label-input">
                      <label>Description*</label>
                      <textarea name="serviceDescription" value={formData.serviceDescription} onChange={handleInputChange} />
                    </div>
                  </div>

                  <div className="ehb-row">
                    <div className="ehb10 right-btn">
                      <button onClick={handleSaveAndList}>Save and List</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      {loading &&
      <div class="kinetic"></div>
     }
    </div>
  );
}

export default EditAdoption;
