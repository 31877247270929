import React from "react";
import { Container, Card, Row, Col } from 'react-bootstrap';
import Logo from '../../assets/media/logo.png';
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import ScrollToTopLink from "./Scroll";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <>
    <div className="footer-bg">
      
      <div className="container footer">
        <Row>
          <Col md={4} className="footer-logo">
            <img src={Logo} alt="Logo" />
            <div className="socials">
              <ScrollToTopLink to="https://www.instagram.com/easyhaibro.petcare/"><FaInstagram /></ScrollToTopLink>
              <ScrollToTopLink to="/about-us"><FaFacebookF /></ScrollToTopLink>
              <ScrollToTopLink to="/privacy-policy"><FaTwitter /></ScrollToTopLink>
              <ScrollToTopLink to="https://www.linkedin.com/company/easyhaibro/"><FaLinkedinIn /></ScrollToTopLink>
            </div>
            <div className="apps">
              <ScrollToTopLink to="https://bit.ly/ehb_play"><img src="https://ehb.easyhaibro.com/auth/v1/media/?file=1714822250782.png" alt="Play Store" target="_blank" /></ScrollToTopLink>
              <ScrollToTopLink to="https://apps.apple.com/in/app/easyhaibro-partner/id6504287880"><img src="https://ehb.easyhaibro.com/auth/v1/media/?file=1714822214988.png" alt="App Store" /></ScrollToTopLink>
             
            </div>
          </Col>
          <Col md={2}>
            <Card style={{ width: '18rem' }}>
              <Card.Header>Company</Card.Header>
              <ScrollToTopLink to="/">Home</ScrollToTopLink>
              <ScrollToTopLink to="https://www.linkedin.com/company/easyhaibro/jobs/" target="_blank">Career</ScrollToTopLink>
              <ScrollToTopLink to="/about-us">About us</ScrollToTopLink>
              <ScrollToTopLink to="/privacy-policy">Privacy Policy</ScrollToTopLink>
              <ScrollToTopLink to="/terms-condition">Terms & Condition</ScrollToTopLink>
              <ScrollToTopLink to="/refund-policy">Refund Policy</ScrollToTopLink>
              
            </Card>
          </Col>
          <Col md={2}>
            <Card style={{ width: '18rem' }}>
              <Card.Header>Our Services</Card.Header>
              <ScrollToTopLink to="/pet-adoption">Pet Adoption</ScrollToTopLink>
              <ScrollToTopLink to="/category/pet-grooming">Pet Grooming</ScrollToTopLink>
              <ScrollToTopLink to="/category/pet-training">Pet Training</ScrollToTopLink>
              <ScrollToTopLink to="/category/pet-boarding">Pet Boarding</ScrollToTopLink>
              <ScrollToTopLink to="/category/dog-walk">Dog Walk</ScrollToTopLink>
              <ScrollToTopLink to="/category/vet-consultation">Vet Consulation</ScrollToTopLink>
            </Card>
          </Col>
          <Col md={2}>
            <Card style={{ width: '18rem' }}>
              <Card.Header>Other Services</Card.Header>
              <ScrollToTopLink to="https://blog.easyhaibro.com/" target="_blank">Blog</ScrollToTopLink>
              <ScrollToTopLink to="/adoption" target="_blank">List your pet</ScrollToTopLink>
              <ScrollToTopLink to="/packers-and-movers" target="_blank">Packers & Movers</ScrollToTopLink>
              <ScrollToTopLink to="https://play.google.com/store/apps/details?id=com.reusablecomp" target="_blank">Service Provider App</ScrollToTopLink>
            
              
            </Card>
          </Col>
          <Col md={2}>
            <Card style={{ width: '18rem' }}>
              <Card.Header>Resources</Card.Header>
              <ScrollToTopLink to="https://api.whatsapp.com/send/?phone=%2B919004044078&text&type=phone_number&app_absent=0" target="_blank">Help Center</ScrollToTopLink>
              <ScrollToTopLink to="" target="_blank">Join Pet Community</ScrollToTopLink>
              <ScrollToTopLink to="https://api.whatsapp.com/send/?phone=%2B919004044078&text&type=phone_number&app_absent=0" target="_blank">Feedback</ScrollToTopLink>
            </Card>
          </Col>
        </Row>
       
      </div>
      </div>
      <div className="footer_end">
        <div className="container end">
          <p>© 2023-2024 Copyright</p>
          <p>RAA EASY HAI BRO SERVICES PRIVATE LIMITED</p>
          <p>All Rights Reserved</p>
          <p>Business Developed By <a href="https://rapydlaunch.com" target="_blank">RapydLaunch</a></p>
        </div>

       
      </div>
    </>
    
  );
};

export default Footer;
