import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner'; // Import Spinner component
import { handleService, useServices } from '../services/services';
import { useNavigate } from "react-router-dom";
import { ShimmerPostItem } from "react-shimmer-effects";

const ServiceComponents = () => {
  const services = useServices();
  const navigate = useNavigate();

  const handleClick = (val) => {
    handleService(val, navigate);
  };
  const handleButtonClick = (service) => {
    window.location.href = `/book-service/${service._id}`;
  };
  
  if (!services) {
    return (
      <Container fluid className="container lg-ds">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="services">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (services.length === 0) {
    return (
      <Container fluid className="container lg-ds">
        <div className="services">
          <h2>Popular Services</h2>
          <p>Browse by services</p>
        </div>
        <Row>
          <div className='ehb-row shimmer-main'>
            <ShimmerPostItem card title cta />
            <ShimmerPostItem card title cta />
            <ShimmerPostItem card title cta />
            <ShimmerPostItem card title cta />
            <ShimmerPostItem card title cta />
            <ShimmerPostItem card title cta />
          </div>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid className="container lg-ds">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="services">
            <h2>Popular Services</h2>
            <p>Browse by services</p>
          </div>
          <div className="category-services">
            {services.map(service => (
              <div className="category-service-repeat" key={service._id}>
                <div className="category-img">
                  <img src={service.serviceImage} alt={`Service Image for ${service.name}`} />
                  <h2>{service.serviceName}</h2>
                </div>
                <div className="category-details">
                  <p id="review-div">
                    <span id="review">{service.reviews} Reviews</span>
                    <span id="number">{` 9.3`} </span>
                  </p>
                  <h3 id="price">₹ {service.servicePrice}/<span id="permonth">{service.serviceText}</span></h3>
                  <div className="service-desc">
                    {/* Display only the first four points of the description */}
                    <div dangerouslySetInnerHTML={{ __html: truncateDescription(service.serviceDescription, 5) }} />
                  </div>
                  <div className="addto">
                    <button id="add" onClick={() => handleButtonClick(service)}>
                      Book now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

// Function to truncate the service description to a specified number of points
const truncateDescription = (description, points) => {
  // Split the description by list items
  const pointsArray = description.split('</li>');

  // Join the first 'points' list items back together
  const truncatedDescription = pointsArray.slice(0, points).join('</li>') + '</li>';

  return truncatedDescription;
};

export default ServiceComponents;
