import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DOMPurify from 'dompurify';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import CT from '../../assets/media/do.png';
import { useParams } from "react-router-dom";
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import moment from "moment-timezone";
import InputMask from 'react-input-mask';

import { css } from '@emotion/react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Forum from "../../component/Forum";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const MySwal = withReactContent(Swal);
const BookService = () => {
  const [currentAddress, setCurrentAddress] = useState("");
  const [address, setAddress] = useState('');
  const [serviceTime, setServiceTime] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());
  const [pincode, setPincode] = useState("");
  const [totalItems, setItems] = useState(1);
  const [serviceSlots, setServiceSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState('');
  const [dropOffDate, setDropOffDate] = useState("");
  const [loading,setLoading]= useState(false);
  const [houseNo,setHouseNo] = useState("");
  const [aprtmentNo,setApartmentNo] = useState("");
  const [serviceDate, setServiceDate] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [serviceData, setServiceData] = useState({});
  const userToken = localStorage.getItem("userToken");
  const [nextDayDate, setNextDayDate] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [payment,setPayment] = useState(false);
  const [codOption, setCodOption] = useState('Yes');

  const navigate = useNavigate();
  const url = window.location.href;
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  const sanitizedHtml = DOMPurify.sanitize(serviceData.serviceDescription);
  const [pickupDate, setPickupDate] = useState(selectedDate);
  const handleSelect = async (selectedAddress) => {
    try {
        const results = await geocodeByAddress(selectedAddress);
        const latLng = await getLatLng(results[0]);
        const isMumbaiArea = results[0].address_components.some((component) =>
            component.types.includes('locality') && (component.long_name === 'Mumbai' || component.long_name === 'Navi Mumbai' || component.short_name === 'NM' ||  component.long_name === 'Thane' )
        );
        if (isMumbaiArea) {
            setAddress(selectedAddress);
            console.log('Selected Address:', selectedAddress);
        } else {
            await MySwal.fire({
                title: "",
                text: "Sorry! currently we are not serving in your area.",
                iconHtml: '😟',
            });
        }
    } catch (error) {
        console.error('Error selecting address:', error);
    }
};



  
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
    setPickupDate(formattedDate);
  }, []);


  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await axios.get(`https://ehb.easyhaibro.com/auth/v1/service/${lastPart}`, {
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
        });

        setServiceData(response.data);
      } catch (error) {
        console.error("Error fetching service details:", error);
      }
    };

    fetchServiceDetails();
  }, [userToken]);

  const handleAddressChange = (event) => {
    setCurrentAddress(event.target.value);
  };


  const allowedPatterns = [
    '4*',
    '5*'
  ];
  
const validatePincode = (value) => {
  for (const pattern of allowedPatterns) {
    const regex = new RegExp('^' + pattern.replace(/\*/g, '\\d*') + '$');
    if (regex.test(value)) {
      return true;
    }
  }
  return false;
};

  const handlePincodeChange = async (event) => {
    const pincode = event.target.value;
  
    if (pincode.length <= 5) {
      setPincode(pincode);
    } else {
      if (validatePincode(pincode)) {
        setPincode(pincode);
      } else {
        await MySwal.fire({
          title: "",
          text: "Sorry! Currently, we are not serving in your area.",
          iconHtml: '😟',
        });
        setPincode();
      }
    }
  };
  
  


  const handleDropOffDateChange = (event) => {
    setDropOffDate(event.target.value);
  };
const handleApartmentChange = (event) => {
  setApartmentNo(event.target.value);
};
const handleHouseChange = (event) => {
  setHouseNo(event.target.value);
};
  const handleGoBack =()=>{
    navigate.goBack()
  }

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const containerStyle = {
    height: expanded ? 'auto' : 'auto',
    overflow: 'hidden',
    transition: 'height 0.3s ease-in-out 0s', 
    minHeight: "0px", 
    position: "relative",
  };
  
  const handlePayment = () => {
    setPayment(true);
    window.scrollTo(0, 0);
  };
  
const displayDetails = ()=>{
  setPayment(false);
}

  const { name } = useParams();
 

  const currentDate = new Date().toISOString().split('T')[0];

  const handleBookingSubmit = async () => {
    try {
      setLoading(true);
      
      const response = await axios.post('https://ehb.easyhaibro.com/auth/v1/booking', {
        ServiceId: name,
        petType: "Dog",
        pickUpDate: selectedDate,
        dropOffDate: dropOffDate,
        petSize: "Medium",
        currentAddress: address,
        bookingStatus: 'pending',
        serviceSlot: selectedSlot.time,
        houseNo: houseNo,
        apartmentNo: aprtmentNo,
        totalBooking: totalItems
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
      });
  
      if (response.status === 201) {
        const orderId = response.data._id;
        console.log("orderId", orderId);
  
        // Book the slot
        let slotData = JSON.stringify({
          "date": selectedDate,
          "time": selectedSlot.time
        });
  
     
  
        // Proceed to payment
        let paymentData = JSON.stringify({
          "orderId": orderId
        });
  
        let paymentConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://ehb.easyhaibro.com/auth/v1/pay',
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${userToken}`
          },
          data: paymentData
        };
  
        const paymentResponse = await axios.request(paymentConfig);
  
        if (paymentResponse.data.paymentSt === "success") {
          const paymentUrl = paymentResponse.data.payment_url;
          window.location.assign(paymentUrl); 
        } else {
        
          console.error("Payment failed:", paymentResponse.data);
          await Swal.fire({
            title: "Payment Failed",
            text: "There was an issue with the payment. Please try again.",
            icon: "error",
          });
        }
  
        setLoading(false);
  
        // await Swal.fire({
        //   title: "Booking Successful",
        //   text: "Your booking has been created successfully!",
        //   icon: "success",
        // });
        // navigate("/my-bookings");
      }
  
    } catch (error) {
      console.error("Error making booking:", error);
      setLoading(false);
      await Swal.fire({
        title: "Error",
        text: "There was an error creating your booking. Please try again.",
        icon: "error",
      });
    }
  };

  const hanldeBookService = async () =>{
    console.log(selectedDate,selectedSlot.time)

    try {
      setLoading(true)

      const response = await axios.post('https://ehb.easyhaibro.com/auth/v1/booking', {
        ServiceId: name,
        petType: "Dog",
        pickUpDate: selectedDate,
        dropOffDate: dropOffDate,
        petSize: "Medium",
        currentAddress: address,
        bookingStatus:'pending',
        serviceSlot:selectedSlot.time,
        houseNo:houseNo,
        apartmentNo:aprtmentNo,
        totalBooking:totalItems
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
      });

      if (response.status === 201) {
        setLoading(false);
      
let data = JSON.stringify({
  "date":selectedDate,
  "time": selectedSlot.time
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://ehb.easyhaibro.com/admin/auth/v1/book-slot',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': `Bearer ${userToken}`
  },
  data : data
};

axios.request(config)
.then((response) => {
 
})
.catch((error) => {
  console.log(error);
});

        await Swal.fire({
            title: "Booking Successful",
            text: "Your booking has been created successfully!",
            icon: "success",
          });
          navigate("/my-bookings");
        
      } 
      
    } catch (error) {
      console.error("Error making booking:", error);
    }
  }
  

  const handleCart = async () => {
    console.log(selectedDate,selectedSlot.time)

    try {
      setLoading(true)

      const response = await axios.post('https://ehb.easyhaibro.com/auth/v1/booking', {
        ServiceId: name,
        petType: "Dog",
        pickUpDate: selectedDate,
        dropOffDate: dropOffDate,
        petSize: "Medium",
        currentAddress: address,
        bookingStatus:'pending',
        serviceSlot:selectedSlot.time,
        houseNo:houseNo,
        apartmentNo:aprtmentNo,
        totalBooking:totalItems,
        orderIn:'cart'
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
      });

      if (response.status === 201) {
        setLoading(false);
      
let data = JSON.stringify({
  "date":selectedDate,
  "time": selectedSlot.time
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://ehb.easyhaibro.com/admin/auth/v1/book-slot',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': `Bearer ${userToken}`
  },
  data : data
};

axios.request(config)
.then((response) => {
 
})
.catch((error) => {
  console.log(error);
});

        await Swal.fire({
            title: "Added to cart",
            text: "Your booking has been added to cart successfully!",
            icon: "success",
          }); 
      } 
      
    } catch (error) {
      console.error("Error making booking:", error);
    }
  };




  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://ehb.easyhaibro.com/auth/v1/get-slot');
        const data = response.data;
        const slotsForDate = data.slots;
        setServiceSlots(response.data);
        setServiceDate(data.date);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching service slots:', error);
      }
    };

    fetchData();
  }, []); 
  const handleSlotClick = (slot) => {
   
    setSelectedSlot(slot);
  };


  const generateTimeSlots = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
  
    // Convert pickupDate to a Date object
    const pickupDateObj = new Date(pickupDate);
  
    // If pickupDate is the same as the current date, display slots from serviceSlots
    if (
      pickupDateObj.getDate() === currentDate.getDate() &&
      pickupDateObj.getMonth() === currentDate.getMonth() &&
      pickupDateObj.getFullYear() === currentDate.getFullYear()
    ) {
      return serviceSlots.map((slot, index) => (
        <option key={index} value={slot}>
          {slot}
        </option>
      ));
    } else {
      const defaultSlots = [];
      for (let hour = 9; hour <= 20; hour++) {
       
        const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
        defaultSlots.push(
          <option key={hour} value={`${formattedHour}:00`}>
            {`${formattedHour}:00`}
          </option>
        );
      }
      
      return defaultSlots;
    }
  };
  
  function tomorrowDate(currentDate) {
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowISOString = tomorrow.toISOString().split('T')[0];
    return tomorrowISOString;
  }

  function todayDate(currentDate) {
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(tomorrow.getDate());
    const tomorrowISOString = tomorrow.toISOString().split('T')[0];
    return tomorrowISOString;
  }

  const handleDec =()=>{
    setItems(totalItems-1)
  }
  const handleInc =()=>{
    setItems(totalItems+1)
  }


  const handleChange = (event) => {
    const selectedOption = event.target.value;
    setCodOption(selectedOption);
    console.log('Selected Cash on Delivery option:', selectedOption);
  };

  return (
    <>
      <Container fluid className="container category">
        <div className="hyperlinks">
          <a href="/" >Home </a> {` `}/
          <a onClick={handleGoBack} id="activeLink">{serviceData.categoryName}</a>
          <h2>{serviceData.categoryName}</h2>
        </div>

        <Container fluid className="container lg-ds">
          <Row className="orderd">
          <Col lg={6} md={12} sm={12}>
      <div className="category-service-repeat book" style={containerStyle} id="containerStyle">
        <div className="category-img">
          <img src={serviceData.serviceImage} alt="Service Thumbnail" />
        </div>
        <div className="category-details">
          <h3 id="price">₹ {serviceData.servicePrice}</h3>
          <p>
            <span id="review">{serviceData.reviews} Reviews</span>{' '}
            <span id="number">{`9.3`}</span>
          </p>
          <p id="descFull" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        </div>
        
      </div>
      <button onClick={handleToggleExpand} id="viewMore">
        {expanded ? 'View Less' : 'View More'}
      </button>
     
    </Col>
            <Col lg={6} md={12} sm={12}>
              {!payment &&
              <div className="booking-details">
                <h2>Book a service!</h2>
                <div className="booking-label">
                <div className="booking-label">



</div>
{/* <div className="booking-label">
  <div className="buttonwithinc">
    <button onClick={handleDec}>-</button>
    <p>{totalItems}</p>
    <button onClick={handleInc}>+</button>
  </div>
</div> */}

<div className="booking-label">
  <label>Service Date*</label>
  <div className="dateSlot">
    {serviceSlots.length > 0 ? (
      serviceSlots.map((data, index) => (
        <p
          id="dateSlot"
          key={index}
          onClick={() => setSelectedDate(data.date)}
          className={selectedDate === data.date ? 'selectedDate' : ''}
        >
          {moment(data.date).format('dd DD')}
        </p>
      ))
    ) : (
      <p>Not Available</p>
    )}
  </div>

  {selectedDate && (
    <div>
      <h2 id="slot">Select a service slot for {moment(selectedDate).format('DD MMMM')}</h2>
      <ul className="avSlot">
        {serviceSlots
          .find((slotData) => moment(slotData.date).isSame(selectedDate, 'day'))
          ?.availableSlots?.length > 0 ? (
          serviceSlots
            .find((slotData) => moment(slotData.date).isSame(selectedDate, 'day'))
            .availableSlots.map((slot, slotIndex) => (
              <li
                key={slotIndex}
                id={slot.booked ? 'avSlotBooked' : 'avSlot'}
                className={selectedSlot === slot ? 'selectedDate' : ''}
                onClick={() => handleSlotClick(slot)}
              >
                {slot.time}
              </li>
            ))
        ) : (
          <p id="avSlotNotAvailable">Not Available Slots</p>
        )}
      </ul>
    </div>
  )}
</div>




                </div>
                {serviceData.categoryName !== 'petGrooming' && serviceData.categoryName !== 'dogWalk' && 
                <div className="booking-label">
                  <label>Drop Off Date*</label>
                  <input type="date" min={pickupDate || currentDate} value={dropOffDate} onChange={handleDropOffDateChange} />
                </div>
}

<div className="booking-label">
                  <label>House/Flat/Block NO.*</label>
                  <input type="text" value={houseNo} onChange={handleHouseChange} />
                  </div>

                  <div className="booking-label">
                  <label>Apartment/Road/Area*</label>
                  <input type="text" value={aprtmentNo} onChange={handleApartmentChange} />
                  </div>
                <div className="booking-label">
                  <label>Current Location*</label>
                  <PlacesAutocomplete
        value={address}
        onChange={(value) => setAddress(value)}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Type Your Current Address...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
                </div>
                <div className="booking-label">
                  <label>Pincode*</label>
                 
                  <InputMask
        mask="999999"
        maskChar=""
        value={pincode}
        onChange={handlePincodeChange}
        placeholder="Enter Pincode"
      />
                </div>
               
                {serviceData && address && pincode ?
  <div className="booking-label btn-two">
                  {/*<input type="submit" id="submit" value="Add to Cart" onClick={handleCart} />*/}
    <input type="submit" id="submit" value="Order now" onClick={()=>handlePayment(serviceData)} />
  </div>
  :
  <div className="booking-label btn-two">
    {/*<input type="submit" id="disable" value="Add to cart" disabled />*/}
    <input type="submit" id="disable" value="Order now" disabled />
  </div>
}



              </div>
              }
               {payment && 
                <div className="booking-details">
                                <h2>Payment Details!</h2>
                <div className="payment-label">
                   <p>Base Fee</p>
                   <p>{serviceData.paymentJSON.baseFee}</p>
                </div>

                <div className="payment-label">
                   <p>Convenience Fee</p>
                   <p>{serviceData.paymentJSON.conFee}</p>
                </div>
                <div className="payment-label">
                   <p>Tax</p>
                   <p>{serviceData.paymentJSON.tax}</p>
                </div>
                <div className="payment-label">
                   <p>Total </p>
                   <p>{serviceData.paymentJSON.servicePrice}</p>
                </div>
                <div className="booking-label">
      <label>Payment Method*</label>
      <select value={codOption} onChange={handleChange}>
      <option value="">Select</option>
        <option value="yes">Pay Now</option>
        <option value="no">Cash After Service</option>
      </select>
    </div>
    {codOption === 'no' && 
<>

</>
               }
  <div className="booking-label">
    <p id="see" onClick={displayDetails}>View Details</p>
    {codOption === 'yes' &&
    <input type="submit" id="submit" value="Proceed to Payment" onClick={handleBookingSubmit} />
               }
                {codOption === 'no' &&
    <input type="submit" id="submit" value="Proceed to (Pay After Service)" onClick={hanldeBookService} />
               }
  </div>
                </div>
               }
            </Col>
          </Row>
        </Container>
      </Container>
     
     
     {loading &&
      <div class="kinetic"></div>
     }
    </>
  );
};

export default BookService;
