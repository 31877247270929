import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FaWhatsapp } from "react-icons/fa";
import DetailsModal from "../modal/Details";
import LoginModal from "../modal/Login";
import { RiPriceTag3Fill } from "react-icons/ri";
import moment from "moment-timezone";

const PetAdoptionComp = ({
  petImage,
  priceRange,
  petName,
  serviceCity,
  petBreed,
  gender,
  btnName,
  serviceId,
  mypet,
  petType,
  dateOfBirth,
  viewDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [redirectServiceId, setRedirectServiceId] = useState(null);

  const isLoggedIn = () => {
    return !!localStorage.getItem("userToken");
  };

  const handleButtonClick = (serviceId) => {
    setLoading(true);
    window.location.href = `https://api.whatsapp.com/send/?phone=%2B919004044078&text&type=phone_number&app_absent=0`;
  };

  const handleEdit = (serviceId) => {
    setLoading(true);
    window.location.href = `/adoption/edit/${serviceId}`;
  };

  const handleOpenModal = (name, serviceId) => {
    if (!isLoggedIn()) {
      setRedirectServiceId(serviceId);
      setShowLoginModal(true);
    } else {
      navigateToPetDetails(serviceId);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const handleDisplayPage = (serviceId) => {
    if (!isLoggedIn()) {
      setRedirectServiceId(serviceId);
      setShowLoginModal(true);
    } else {
      navigateToPetDetails(serviceId);
    }
  };

  const navigateToPetDetails = (serviceId) => {
    window.location.href = `/pet-adoption/${serviceId}`;
  };

  const handleToggle = (serviceId) => {
    setLoading(true);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}toggle/${serviceId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Status changed successfully.",
          }).then(() => {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "An error occurred while changing status.",
        });
      });
  };

  const dob = moment(dateOfBirth, "YYYY-MM-DD");
  const now = moment();

  const years = now.diff(dob, "years");
  dob.add(years, "years");

  const months = now.diff(dob, "months");

  const handleLoginSuccess = () => {
    setShowLoginModal(false);
    if (redirectServiceId) {
      navigateToPetDetails(redirectServiceId);
    }
  };

  return (
    <>
      {petImage && (
        <div className="adoption-dv">
          <div
            className="banner-add"
            onClick={() => handleDisplayPage(serviceId)}
          >
            <img src={petImage} alt={petName} />
          </div>

          <div className="adpd">
            <h2>{petName}</h2>
            <div
              className="icon-address-container"
              onClick={() => handleDisplayPage(serviceId)}
            >
              {serviceCity && (
                <p>
                  Address: <bold>{serviceCity}</bold>
                </p>
              )}
              <p>
                Age: <bold>{years} years {months} months</bold>
              </p>
              <p>
                Breed: <bold>{petBreed}</bold>
              </p>
              <p>
                Sex: <bold>{gender}</bold>
              </p>
            </div>

            {btnName && (
              <div className="adp-btn">
                <button
                  id="whatsapp"
                  onClick={() => handleButtonClick(serviceId)}
                >
                  <FaWhatsapp id="waIcon" />
                  {` `} {btnName}
                </button>
              </div>
            )}

            <div className="adp-btn new-btn">
              <button onClick={() => handleOpenModal(petName, serviceId)} className="main-btn">
                <RiPriceTag3Fill />{` `}Check Price
              </button>
              <DetailsModal
                show={showModal}
                handleClose={handleCloseModal}
                petname={petName}
                gender={gender}
                serviceId={serviceId}
              />
              <LoginModal
                show={showLoginModal}
                handleClose={() => setShowLoginModal(false)}
                onLoginSuccess={handleLoginSuccess}
              />
            </div>

            {mypet && (
              <div className="adp-btn">
                <button onClick={() => handleEdit(serviceId)}>
                  {viewDetails}
                </button>
                {mypet === "active" && (
                  <button onClick={() => handleToggle(serviceId)} id={mypet}>
                    {loading && <span>Loading...</span>}
                    {!loading && <span>{mypet}</span>}
                  </button>
                )}
                {mypet === "inactive" && (
                  <button onClick={() => handleToggle(serviceId)} id={mypet}>
                    {loading && <span>Loading...</span>}
                    {!loading && <span>{mypet}</span>}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PetAdoptionComp;
