import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import PetAdoptionComp from "../../../component/Service";
import axios from 'axios';
import { ShimmerPostItem } from "react-shimmer-effects";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FilterComponent from "../../../component/FilterComponent";
import Testimonials from "../../../component/Testimonials";
import FAQComponent from "../../../component/FAQComponent";

const DogType = () => {
  const [petData, setPetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  
  const isLoggedIn = !!localStorage.getItem("userToken");

  useEffect(() => {
    getData({});
  }, []);

  const getData = (filters) => {
    setLoading(true);

    // Construct the query parameters, removing any empty values
    const queryParams = new URLSearchParams({
      petType: 'dog',
      ...Object.fromEntries(Object.entries(filters).filter(([key, value]) => value))
    }).toString();

    const url = `${process.env.REACT_APP_HOST}adoption?${queryParams}`;
    console.log("Fetching URL:", url); // Debug: log the URL

    axios.get(url)
      .then((response) => {
        console.log("API Response:", response.data); // Debug: log the response

        if (response.data.status === true) {
          setPetData(response.data.data);
          setFilteredData(response.data.data);
        } else {
          setFilteredData([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error); // Debug: log the error
        setLoading(false);
      });
  };

  const placeholderItems = Array.from({ length: 4 });

  const handleFilter = (filters) => {
    console.log("Filters applied:", filters); // Debug: log the filters
    getData(filters);
  };

  return (
    <Container>
      <div className="top Dog-container" />
      <FilterComponent onFilter={handleFilter} />

      <Container fluid className="container lg-ds">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="services service-head">
              <h2>Type dog</h2>
              <p>Find Your Buddy!</p>
              <h4 id="hide">Find Your Buddy!</h4>
            </div>
          </Col>
        </Row>
      </Container>

      {!loading && filteredData.length === 0 && (
        <div className="ehb-row">
          <div className="ehb5">
            <img src="https://ehb.easyhaibro.com/auth/v1/media/?file=1714835715741.png" alt="404" />
          </div>
          <div className="ehb5 ehb-404">
            <h2>404 Pet Not Found 🥺</h2>
            <p>Dog/Cat are not our whole life, but they make our lives whole</p>
          </div>
        </div>
      )}

      <div className="adoption">
        {loading && (
          <div className="category-shimmer">
            {placeholderItems.map((_, index) => (
              <ShimmerPostItem
                key={index}
                card
                title
                cta
                imageType="thumbnail"
                imageWidth={50}
                imageHeight={50}
                contentCenter
              />
            ))}
          </div>
        )}

        {!loading && filteredData.length > 0 && (
          <>
            {filteredData.map((pet, index) => (
              <PetAdoptionComp
                key={index}
                petImage={pet.serviceImage}
                priceRange={`${pet.servicePrice}`}
                petName={pet.serviceName}
                address={pet.petBreed}
                gender={pet.petGender}
                dateOfBirth={pet.dateOfBirth}
                btnName="Whatsapp"
                serviceCity={pet.serviceCity}
                serviceId={pet._id}
                petBreed={pet.petBreed}
              />
            ))}
          </>
        )}
      </div>
      
      <Testimonials/>
      <FAQComponent/>
    </Container>
  );
};

export default DogType;
