import { React } from "react";
import CarouselComponent from '../../component/CarouselComponent';
import HowItWorksComponent from '../../component/HowItWorksComponent';
import ReviewsSection from '../../component/ReviewsSection';
import FAQComponent from '../../component/FAQComponent';
import ServiceComponents from '../../component/ServiceComponents';
import JsonLd from "../../component/JsonLd";
import FamousServices from "../../component/FamousServices";
import Testimonials from "../../component/Testimonials";

const homepageSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "url": "https://easyhaibro.com/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://easyhaibro.com/search?q={search_term_string}",
    "query-input": "required name=search_term_string"
  }
};


function Homescreen() { 

  return (
    <>
        <JsonLd data={homepageSchema} />
  <CarouselComponent />
  <ServiceComponents  />
  <FamousServices />
  <HowItWorksComponent />
  <ReviewsSection />
  {/* <Testimonials />       */}
  <FAQComponent />      
    </>
  );
}

export default Homescreen;
